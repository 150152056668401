<template>
  <div class="add-new-empoyee">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.skillUpdate')"
      :title="$t('messages.skillUpdate')"
      @submitPressed="submitPressed"
    />
    <skills-form
      v-if="skill"
      :skillObject="skill"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import SkillsForm from "./components/SkillsForm.vue";

export default {
  components: {
    TitleBar,
    SkillsForm,
  },
  data() {
    return {
      skill: null,
      action: null,
      skillList: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const id = this.$route.params.id;
      this.$Skills.getResource({ id }).then((response) => {
        this.skill = response.data;
        this.skill.skillTypeSelect = {
          label: this.skill.typeName,
          id: this.skill.type["@id"],
        };
        this.skill.categorySelect = {
          label: this.$helper.getEnumTranslation(
            "SKILL_CATEGORY",
            this.skill.category,
            this.$i18n.locale
          ),
          id: this.skill.category,
        };
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
